<template>
  <div class="top" id="home">
    <picture>
      <source media="(max-width: 480px)" srcset="../../assets/images/homeview/muraki_top_forsp_2000.png 1125w">
      <source media="(min-width: 481px)" srcset="../../assets/images/homeview/muraki_top_forpc_668.png 1366w">
      <img src="../../assets/images/homeview/muraki_top_forpc_668.png" alt="muraki-top">
    </picture>
    <!-- <img 
      alt="top-image"
      srcset="../../assets/images/muraki_top_forpc.png 1366w, ../../assets/images/muraki_top_forsp.png 1125w"
      src="../../assets/images/muraki_top_forpc.png"> -->
    <div class="text-contents">
      <div class="title">
        <p class="title-item">Ryu Muraki</p>
        <span class="subtitle">
          <p class="subtitle-item subtitle-item-1">Clarinet and Sax</p>
          <p class="subtitle-item subtitle-item-2">Player</p>
        </span>
      </div>
    </div>
    <div class="scroll">
      <p href="#">Scroll</p>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" scoped></style>

<style lang="scss" scoped></style>

<script setup>
  import { onMounted } from 'vue'

  onMounted(() => {
    const titleItems = document.querySelectorAll('.title-item')
    const subtitleItems = document.querySelectorAll('.subtitle-item')
    const scrollDown = document.querySelectorAll('.scroll')

    setTimeout(() => {
      titleItems.forEach(item => item.classList.add('is-visible'))
      subtitleItems.forEach(item => item.classList.add('is-visible'))
    }, 500)

    setTimeout(() => {
      scrollDown.forEach(item => item.classList.add('is-visible'))
    }, 1500)
  })
</script>
