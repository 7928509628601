<script setup>
import { onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import HeaderForPosts from "../../components/header/HeaderForPosts.vue";
import { db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { storage } from "@/firebase";
import { ref as storageRef, getDownloadURL } from "firebase/storage";
import { getYear, getMonth, getDay, getWeekdayKanji } from "@/assets/js/common";
import dayjs from "dayjs";
import { useLoadingStore } from "@/stores/loading";
import LoadingSpinner from "@/components/common/Loading.vue";

const loadingStore = useLoadingStore();
const isLoading = computed(() => loadingStore.isLoading);

const route = useRoute();
const imageUrl = ref(null);
let live = ref({});

const fetchPostInfo = async (docId) => {
  try {
    loadingStore.setLoading(true);
    const docRef = doc(db, "lives", docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("データが存在しません");
      return {};
    }
  } catch (error) {
    console.error(error);
    return {};
  } finally {
    loadingStore.setLoading(false);
  }
};

const fetchImageUrl = async () => {
  const imagePath = live.value.flyer;
  const imageRef = storageRef(storage, imagePath);
  try {
    imageUrl.value = await getDownloadURL(imageRef);
  } catch (error) {
    console.error("Failed to fetch image:", error);
  }
};

onMounted(async () => {
  const id = route.params.id;
  live.value = await fetchPostInfo(id);
  const weekdayCode = dayjs(live.value.event_date).format("d");
  live.value.weekday = getWeekdayKanji(weekdayCode);
  fetchImageUrl();
});
</script>

<template>
  <HeaderForPosts></HeaderForPosts>

  <div class="inner">
    <LoadingSpinner class="live-content loading" />
    <div class="live-content" v-if="!isLoading">
      <div class="head">
        <p class="update-date">更新日 {{ live.updated_at }}</p>
        <div class="title">
          <div class="title-text">
            <div class="point"></div>
            <h1>{{ live.title }}</h1>
          </div>
          <p class="subtitle">
            {{ live.subTitle }}
          </p>
        </div>
      </div>

      <div class="flyer" v-if="imageUrl">
        <a
          v-if="live.ticketUrl"
          :href="live.ticketUrl"
          target="_blank"
          rel="noopener noreferrer"
          class="event-url"
        >
          <img :src="imageUrl" alt="" />
        </a>
        <img :src="imageUrl" alt="" v-else />
      </div>

      <div class="info">
        <h2 class="heading">
          <font-awesome-icon icon="music" style="margin-right: 5px" />
          INFO
        </h2>
        <div class="basic-info">
          <p v-if="live.event_date">
            <span>{{ getYear(live.event_date) }}</span
            >年 <span>{{ getMonth(live.event_date) }}</span
            >月 <span>{{ getDay(live.event_date) }}</span
            >日 (<span>{{ live.weekday }}</span
            >)
          </p>
          <p v-if="live.time">
            <span v-if="live.open_time">Open {{ live.open_time }} /</span>
            <span>Start {{ live.start_time }}</span>
          </p>
          <div v-if="live.venue_name">
            <div>
              <font-awesome-icon icon="location-dot" />
              {{ live.venue_name }}
              <a
                :href="live.venue_website"
                target="_blank"
                rel="noopener noreferrer"
                v-if="live.venue_website"
              >
                <button>会場HPはこちら</button>
              </a>
            </div>
          </div>
        </div>
        <div class="performer" v-if="live.performers">
          <h2 class="heading">
            <font-awesome-icon icon="guitar" style="margin-right: 5px" />PERFORM
          </h2>
          <div v-for="(performer, index) in live.performers" :key="index">
            {{ performer.role }} {{ performer.name }}
            <span v-if="performer.isGuest">(Guest)</span>
          </div>
        </div>
        <div class="charge" v-if="live.charge">
          <h2 class="heading">
            <font-awesome-icon icon="ticket" style="margin-right: 5px" />CHARGE
          </h2>
          <div class="charge-info">
            <p
              v-if="live.charge.is_adv_door_type && live.charge.adv"
              class="charge-item"
            >
              <span class="charge-label">予約</span>
              <span class="charge-value">¥{{ live.charge.adv }}</span>
            </p>
            <p
              v-if="live.charge.is_adv_door_type && live.charge.door"
              class="charge-item"
            >
              <span class="charge-label">当日</span>
              <span class="charge-value">¥{{ live.charge.door }}</span>
            </p>
            <p
              v-if="!live.charge.is_adv_door_type && live.charge.flat_charge_label && live.charge.flat_charge_value"
              class="charge-item"
            >
              <span class="charge-label">{{ live.charge.flat_charge_label  }}</span>
              <span class="charge-value">¥{{ live.charge.flat_charge_value }}</span>
            </p>
            <p v-if="live.charge.streaming" class="charge-item">
              <span class="charge-label">配信</span>
              <span class="charge-value">¥{{ live.charge.streaming }}</span>
            </p>
            <p v-if="live.charge.order" class="charge-item">
              <span class="charge-label">オーダー</span>
              <span class="charge-value">{{ live.charge.order }}</span>
            </p>
            <p v-if="live.charge.tip" class="charge-item tip">
              <span class="charge-label">投げ銭</span>
              <span class="charge-value">あり</span>
            </p>
            <p v-if="live.charge.other_charge_info" class="charge-item">
              <span class="charge-label">その他情報</span>
              <span class="charge-value">{{
                live.charge.other_charge_info
              }}</span>
            </p>
          </div>
        </div>
        <div class="reservation" v-if="live.ticket_url">
          <h2 class="heading">
            <font-awesome-icon icon="phone" style="margin-right: 5px" />
            CONTACT
          </h2>
          <p v-if="live.tel" class="charge-item">
            <span class="charge-label">お問い合わせ</span>
            <span class="charge-value"
              ><font-awesome-icon icon="phone" style="margin-right: 5px" />{{
                live.tel
              }}</span
            >
          </p>
          <a :href="live.ticket_url" target="_blank" rel="noopener noreferrer">
            <button>イベント詳細・ご予約はこちら</button>
          </a>
        </div>
      </div>
      <RouterLink to="/">
        <div class="home">
          <font-awesome-icon icon="angle-left" />
          <p>一覧へもどる</p>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" scoped></style>
