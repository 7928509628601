<template>
  <div class="admin">
    <h2>ライブ情報投稿</h2>
    <form @submit.prevent="submitForm">
      <dl>
        <dt>タイトル</dt>
        <dd>
          <input type="text" v-model="title">
        </dd>

        <dt>日付</dt>
        <dd>
          <input type="date" v-model="date">
        </dd>
        
        <dt>OPEN</dt>
        <dd>
          <input type="time" v-model="open">
        </dd>
        
        <dt>START</dt>
        <dd>
          <input type="time" v-model="start">
        </dd>
        
        <dt>画像</dt>
        <dd>
          <input type="file" @change="onFileChange">
        </dd>
      </dl>

      <button type="submit">投稿する</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getAuth } from 'firebase/auth'
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage, ref as storageRef } from 'firebase/storage'

const router = useRouter()
const auth = getAuth()
const db = getFirestore()
const storage = getStorage()

const title = ref('')
const date = ref('')
const open = ref('')
const start = ref('')
const file = ref(null)

const onFileChange = (event) => {
  file.value = event.target.files[0]
}

const submitForm = async () => {
  const user = auth.currentUser
  // if (!user) {
  //   // ログインしていない場合、ログインページにリダイレクトする
  //   router.push('/login')
  //   return
  // }

  // Firestoreにデータを保存
  const docRef = await db.collection('posts').add({
    title: title.value,
    date: date.value,
    imageUrl: null, // 画像ファイルのダウンロードURL
    authorId: user.uid,
    createdAt: serverTimestamp()
  })

  // Firebase Storageに画像ファイルをアップロード
  if (file.value) {
    const fileRef = storageRef(storage, `images/${docRef.id}/${file.value.name}`)
    await fileRef.put(file.value)

    // 画像ファイルのダウンロードURLをFirestoreに更新
    const imageUrl = await fileRef.getDownloadURL()
    await docRef.update({
      imageUrl: imageUrl
    })
  }

  // 投稿一覧ページにリダイレクト
  router.push('/admin')
}
</script>

<style>
.admin {
  color: #fff;
}
</style>