import { createRouter, createWebHistory } from 'vue-router'
import Top from '../pages/top/Top.vue'
import Post from '../pages/post/Post.vue'
import Admin from '../pages/admin/Admin.vue'

const routes = [
  {
    path: '/',
    name: 'top',
    component: Top
  },
  {
    path: '/post/:id',
    name: 'post',
    component: Post
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router