<script setup>
import { ref, onMounted } from "vue";

const isModalVisible = ref(false);

const handleClickPhoto = (href) => {
  if(href) {
    location.href = "https://yokohamareunion.art/products/playin-the-hero"
  } else {
    isModalVisible.value = true;
  }
};

const closeModal = () => {
  isModalVisible.value = false;
};

const images = [
  {
    src: require("../../assets/images/work/rm3_top.jpeg"),
    alt: "",
  },
  {
    src: require("../../assets/images/work/rm3_top2.jpeg"),
    alt: "",
  },
  {
    src: require("../../assets/images/work/rm3_3.jpeg"),
    alt: "",
  },
];
const imagesForPC = [
  {
    src: require("../../assets/images/work/playing_the_hero.png"),
    alt: "",
    href: 'https://yokohamareunion.art/products/playin-the-hero'
  },
  {
    src: require("../../assets/images/work/rm3_top.jpeg"),
    alt: "",
  },
  {
    src: require("../../assets/images/work/rm3_top2.jpeg"),
    alt: "",
  },
  {
    src: require("../../assets/images/work/rm3_3.jpeg"),
    alt: "",
  },
];

// ｼｭﾝ
const shrinkTitle = ref(null);
const shrinkElement = ref(null);
onMounted(() => {
  window.addEventListener("scroll", shrinkTitleOnScroll);
  window.addEventListener("scroll", shrinkElementOnScroll);
});

const shrinkTitleOnScroll = () => {
  const element = shrinkTitle.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add("-show");
      window.removeEventListener("scroll", shrinkTitleOnScroll);
    }
  }
};
const shrinkElementOnScroll = () => {
  const element = shrinkElement.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add("-show");
      window.removeEventListener("scroll", shrinkElementOnScroll);
    }
  }
};
</script>

<template>
  <div class="work" id="work">
    <div class="background-content">
      <p class="background-right shrink-title -right" ref="shrinkTitle">WORK</p>
    </div>

    <div class="work-content shrink-element" ref="shrinkElement">
      <div class="head">
        <div class="title">
          <div class="point"></div>
          <h2>Ryu Muraki Three</h2>
        </div>
        <div class="description">
          <div class="photos -rm3 -pc">
            <img
              v-for="(image, index) in imagesForPC"
              :src="image.src"
              :alt="image.alt"
              :key="index"
              @click="handleClickPhoto(image.href)"
            />
          </div>
          <div class="photos -rm3 -sp">
            <!-- <img class="top" src="../../assets/images/work/rm3_top.jpeg" alt=""> -->
            <a href="https://yokohamareunion.art/products/playin-the-hero">
              <img
                class="top"
                src="../../assets/images/work/playing_the_hero.png"
                alt=""
              />
            </a>
          </div>
          <div class="text -japanese">
            <p>
              <span>村木龍</span
              >(クラリネット)、<span>市川空</span>(ピアノ)、<span>海老原颯</span>(ドラム)の
              3 人により 2021 年結成。
            </p>
            <p>
              スイングジャズ期に活躍した「ベニーグッドマン・トリオ」のスタイルを踏襲し、スイングナンバー、ジャズスタンダードを通じて、その魅力を広く伝えながら、自分たちの音楽を追求する。
            </p>
            <p>
              Instagramでの演奏動画は 2
              万回再生を記録し、首都圏ライブハウスのほか、2022
              年には「けやき音楽祭JAZZ in FUCHU」に出演。好評を博す。
            </p>
            <p>
              またスイングジャズ期にアメリカで流行した「スイング・ダンス（リンディホップ/バルボア）」のイベントにてパーティバンドとして演奏するなど、幅広く精力的に演奏活動を行う。
            </p>
            <p>
              2023年11月、Yokohama Reunionより1stアルバム「Playin' The
              Hero」発売。
            </p>
            <p>
              第41回浅草ジャズコンテスト・バンド部門、銀賞受賞。
            </p>
          </div>
          <div class="text -english">
            <p>
              "Ryu Muraki Three" was formed in 2021 by Ryu Muraki (clarinet),
              Sora Ichikawa (piano), and So Ebihara (drums).
            </p>
            <p>
              They follow the style of the "Benny Goodman Trio" that was active
              during the swing jazz era, and pursue their own music through
              swing numbers and jazz standards, while spreading their appeal
              widely.
            </p>
            <p>
              Their performance videos on Instagram have received 20,000 views,
              and they have performed at live houses in the Tokyo metropolitan
              area as well as at the "Keyaki Music Festival JAZZ in FUCHU" in
              2022. It received favorable reviews.
            </p>
            <p>
              They have also been performing energetically in a wide range of
              events, including as a party band at "Swing Dance (Lindy
              Hop/Balboa)" events, which were popular in the U.S. during the
              swing jazz era.
            </p>
          </div>
        </div>
      </div>
      <div class="photos -sp">
        <img
          v-for="(image, index) in images"
          :src="image.src"
          :alt="image.alt"
          :key="index"
          @click="openModal"
          class="sub"
        />
      </div>
    </div>

    <transition name="modal-fade">
      <div v-if="isModalVisible" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <h2>Ryu Muraki Three</h2>
          <div class="iframe-wrapper">
            <iframe
              src="https://www.youtube.com/embed/GFQ0FgSGMls"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <p>
            村木龍(クラリネット)、市川空(ピアノ)、海老原颯(ドラム)の3人により2021年結成。
          </p>
          <p>
            スイングジャズ期に活躍した「ベニーグッドマン・トリオ」のスタイルを踏襲し、スイングナンバー、ジャズスタンダードを通じて、その魅力を広く伝えながら、自分たちの音楽を追求する。
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" src="./style.scss" scoped></style>
