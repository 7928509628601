import { weekdayMaster } from "./codeMaster";

export const getYear = (date) => date.slice(0, 4);

export const getMonth = (date) => date.slice(5, 7);

export const getDay = (date) => date.slice(8, 10);

export const getWeekdayKanji = (weekdayCode) => {
  return weekdayMaster[weekdayCode]?.kanji;
};
