<template>
  <div class="contact" id="contact">
    <div class="background-content">
      <p class="background-left shrink-title -left" ref="shrinkTitle">CONTACT</p>
    </div>
    <div class="contact-contents">
      <div class="wrapper shrink-element" ref="shrinkElement">
        <div class="caption">
          <p>ライブのご予約や、演奏のご依頼/ご相談など、<br>お気軽にご連絡ください。</p>
          <p class="-english">Please feel free to contact us <br> for live reservations, performance inquiries, or any other
            questions you may have.</p>
        </div>
        <div class="form">
          <form action="https://formspree.io/f/moqzrbnb" method="POST">
            <div>
              <input type="text" id="name" name="name" placeholder="名前 / Name" required>
            </div>
            <div>
              <input type="email" id="email" name="email" placeholder="メールアドレス / Email" required>
            </div>
            <div>
              <textarea id="message" name="message" placeholder="メッセージ / Message" required></textarea>
            </div>
            <input type="hidden" name="_next" value="https://ryumuraki-portfolio.web.app/" />
            <div class="submit-button">
              <button type="submit">送信</button>
            </div>
          </form>
          <div class="sns">
            <p>またはDMへ</p>
            <div class="icons">
              <div class="icon">
                <a href="https://twitter.com/clarinet_ryu">
                  <font-awesome-icon :icon="['fab', 'x-twitter']" class="fontawesome" />
                </a>
              </div>
              <div class="icon">
                <a href="https://www.instagram.com/clarinet_ryu/">
                  <font-awesome-icon :icon="['fab', 'instagram']" class="fontawesome" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const shrinkTitle = ref(null);
const shrinkElement = ref(null);
onMounted(() => {
  const title = shrinkTitle.value;
  if (title) {
    window.addEventListener('scroll', shrinkTitleOnScroll);
  }
  const element = shrinkElement.value;
  if (element) {
    window.addEventListener('scroll', shrinkElementOnScroll);
  }
});

const shrinkTitleOnScroll = () => {
  const element = shrinkTitle.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add('-show');
      window.removeEventListener('scroll', shrinkTitleOnScroll);
    }
  }
};
const shrinkElementOnScroll = () => {
  const element = shrinkElement.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add('-show');
      window.removeEventListener('scroll', shrinkElementOnScroll);
    }
  }
};

</script>

<style lang="scss" src="./style.scss" scoped></style>
