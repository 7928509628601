export const weekdayMaster = {
  '0': {
    english: "sun",
    kanji: "日",
  },
  '1': {
    english: "mon",
    kanji: "月",
  },
  '2': {
    english: "mon",
    kanji: "火",
  },
  '3': {
    english: "mon",
    kanji: "水",
  },
  '4': {
    english: "mon",
    kanji: "木",
  },
  '5': {
    english: "mon",
    kanji: "金",
  },
  '6': {
    english: "mon",
    kanji: "土",
  },
}