import { initializeApp } from 'firebase/app'
// import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD58ieI-CuhbQmOClyFPvOaX3DyyDUG22I",
  authDomain: "ryumuraki-portfolio.firebaseapp.com",
  projectId: "ryumuraki-portfolio",
  storageBucket: "ryumuraki-portfolio.appspot.com",
  messagingSenderId: "556715758605",
  appId: "1:556715758605:web:8b579be51ab5731c74b144",
  measurementId: "G-RDBL12GYF8"
};

const app = initializeApp(firebaseConfig)
// const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

export { db, storage };