<template>
  <div class="footer">
    <p>© Ryu Muraki</p>
  </div>
</template>

<script setup>

</script>

<style lang="scss" src="./style.scss" scoped></style>
