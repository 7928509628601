import { createApp } from 'vue';
import App from './App.vue';
import router from './routes/routes';
import VueScrollTo from 'vue-scrollto';
import 'swiper/swiper-bundle.css';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { createPinia } from 'pinia';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas, fab);

const firebaseConfig = {
  apiKey: "AIzaSyD58ieI-CuhbQmOClyFPvOaX3DyyDUG22I",
  authDomain: "ryumuraki-portfolio.firebaseapp.com",
  projectId: "ryumuraki-portfolio",
  storageBucket: "ryumuraki-portfolio.appspot.com",
  messagingSenderId: "556715758605",
  appId: "1:556715758605:web:8b579be51ab5731c74b144",
  measurementId: "G-RDBL12GYF8"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);

const pinia = createPinia();

const app = createApp(App);

app.use(router);
app.use(VueScrollTo);
app.component('font-awesome-icon', FontAwesomeIcon);

app.provide('auth', auth);
app.provide('db', db);

app.use(pinia);

app.mount('#app');
