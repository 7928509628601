<template>
  <div class="spinner-container" aria-label="読み込み中" v-if="isLoading">
    <div class="spinner"></div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useLoadingStore } from '@/stores/loading';

const loadingStore = useLoadingStore();
const isLoading = computed(() => loadingStore.isLoading);
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
}

.spinner {
  height: 24px;
  width: 24px;
  border: 4px solid #8b4513; /* amber-900 equivalent */
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
