<template>
  <div class="wrapper">
    <div class="burger" @click="toggleMenu">
      <font-awesome-icon :icon="menuOpened ? 'times' : 'bars'" :class="menuOpened ? 'times' : 'bars'" class="icon fa-2x" />
    </div>
    <!-- <p>これはテストページです</p> -->
    <div class="sns-accounts">
      <p>@clarinet_ryu</p>
      <div class="icons">
        <a href="https://twitter.com/clarinet_ryu">
          <font-awesome-icon :icon="['fab', 'x-twitter']" class="fontawesome" />
        </a>
        <a href="https://www.instagram.com/clarinet_ryu/">
          <font-awesome-icon :icon="['fab', 'instagram']" class="fontawesome" />
        </a>
      </div>
    </div>
    <transition name="modal-fade">
      <div v-if="menuOpened" class="modal-overlay" @click.self="toggleMenu">
        <div class="menu modal-content">
          <div class="anchor-link">
            <a href="/">HOME</a>
            <a href="/#schedule">SCHEDULE</a>
            <a href="/#profile">PROFILE</a>
            <a href="/#work">WORK</a>
            <a href="/#contact">CONTACT</a>
          </div>
          <div class="sns-link">
            <a href="https://twitter.com/clarinet_ryu">
              <div class="sns-link-item">
                <font-awesome-icon :icon="['fab', 'x-twitter']" class="fontawesome" />
              </div>
            </a>
            <a href="https://www.instagram.com/clarinet_ryu/">
              <div class="sns-link-item">
                <font-awesome-icon :icon="['fab', 'instagram']" class="fontawesome" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
  import { ref } from 'vue'

  const menuOpened = ref(false)

  const toggleMenu = () => {
    menuOpened.value = !menuOpened.value
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    position: absolute;
    z-index: 5;
    position: fixed;
    
    color: #111;
    top: 20px;
    left: 20px;
    right: 20px;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .burger {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
  }

  .burger .icon {
    transition: all 0.3s ease-in-out;
    position: fixed;
  }

  .menu {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column; 
    color: #111;
    opacity: 0.9;
  }

  .sns-accounts {
    .icons {
      text-align: right;      
    }
  }


  .anchor-link {
    padding: 20px;
    a {
      display: block;
      margin: 18px 0;
      text-align: center;
      cursor: pointer;
      font-size: 1.2rem;
    }
  }


  .sns-link {
    color: aliceblue;
    padding: 20px;
    display: flex;
    margin: 0 auto;
    &-item {
      background-color: #111;
      border: #fff solid 2px;
      border-radius: 5px;
      padding: 20px;
      text-align: center;
      .fontawesome {
        margin: 5px;
      }
    }
  }

  /* モーダルメニュー用のスタイル */
  .modal-overlay {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    z-index: 10000;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: #111;
    opacity: 0.9;
    max-width: 400px;
    width: 80%;
  }

    /* バーガーアイコンからバツアイコンへのアニメーション */
  .burger .icon.bars {
    transform: rotate(0deg);
  }

  .burger .icon.times {
    transform: rotate(90deg);
  }

  .burger .icon.times:before {
    top: 0;
    transform: rotate(-90deg);
  }

  .burger .icon.times:after {
    top: 0;
    transform: rotate(90deg);
  }

    /* フェードイン・フェードアウトのトランジションアニメーション */
  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: all 0.3s ease;
  }

  .modal-fade-enter-from,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active .modal-overlay,
  .modal-fade-leave-active .modal-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-fade-enter-active .modal-content,
  .modal-fade-leave-active .modal-content {
    opacity: 0.9;
  }
</style>
