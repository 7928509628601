<script setup>
import { ref, onMounted } from 'vue'

const shrinkTitle = ref(null);
const shrinkElement = ref(null);
onMounted(() => {
  window.addEventListener('scroll', shrinkTitleOnScroll);
  window.addEventListener('scroll', shrinkElementOnScroll);
});

let selectedLanguage = ref('japanese')

const switchToJapanese = () => {
  if(selectedLanguage.value === 'english') {
    selectedLanguage.value = 'japanese'
  }
}
const switchToEnglish = () => {
  if(selectedLanguage.value === 'japanese') {
    selectedLanguage.value = 'english'
  }
}

const shrinkTitleOnScroll = () => {
  const element = shrinkTitle.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add('-show');
      window.removeEventListener('scroll', shrinkTitleOnScroll);
    }
  }
};
const shrinkElementOnScroll = () => {
  const element = shrinkElement.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add('-show');
      window.removeEventListener('scroll', shrinkElementOnScroll);
    }
  }
};

</script>
    
<template>
  <div class="profile" id="profile">
    <div class="background-content">
      <p class="background-left shrink-title -left" ref="shrinkTitle">PROFILE</p>
    </div>
    <div class="profile-contents shrink-element" ref="shrinkElement">
      <div class="text">
        <img src="../../assets/images/profile/profile.jpg" alt="profile">
        <div class="text-content -japanese">
          <div class="name">
            <span>村木龍 Ryu Muraki</span>
            <div class="toggle">
              <button 
                @click="switchToJapanese"
                :class="selectedLanguage === 'japanese' ? '-selected' : ''"
              >日本語</button>
              <button
                @click="switchToEnglish"
                :class="selectedLanguage === 'english' ? '-selected' : ''"
              >English</button>
            </div>
          </div>
          <div class="description -japanese" v-if="selectedLanguage === 'japanese'">
            <p>
              クラリネット、サックス奏者。1999年、神奈川県出身。
            </p>
            <p>
              クラリネット、ピアノ、ドラムによるスイングジャズ・バンド"Ryu Muraki Three"のほか、<br>
              自己のリーダーバンドを率いて、またサポートメンバーとして首都圏を中心に演奏活動を行う。
            </p>
            <p>
              増圭介氏率いるジャズ吹奏楽団Wind Rootsにてクラリネットを担当。<br>
              ジャズを主軸としつつ、クラシック、即興演奏の分野にも力を入れる。
            </p>
            <p>
              第22回大阪国際音楽コンクール木管楽器部門Age-U入選。第41回浅草ジャズコンテスト・バンド部門、ベストプレーヤー賞受賞。
            </p>
            <p>
              クラリネットを益田英生、赤坂達三、テナーサックスと作曲を吉本章紘、ジャズ理論を土田晴信の各氏に師事。
            </p>
            <div class="history">
              <p class="history-title">
                <font-awesome-icon :icon="['fas', 'landmark']" />
                略歴
              </p>
              <p>
                中学校吹奏楽部にてクラリネットを始め、ジャズと出会う。
              </p>
              <p>
                2018年<br>
                早稲田大学モダンジャズ研究会に入部。在籍中には幹部職を担当したほか、本格的にライブ演奏活動を始める。<br>
              </p>
              <p>
                2020年<br>
                立教大学New Swingin' Herd レギュラー・バンドに参加、テナーサックスを担当。同年開催された「第51回山野楽器ビッグバンド・ジャズ・コンテスト」に出場。<br>
              </p>
              <p>
                2021年<br>
                Ryu Muraki Threeを結成。準備期間を経たのち、首都圏ライブハウスのほか、「けやき音楽祭JAZZ in FUCHU
                2022」、また都内で開催されるスイングダンス(リンディホップ・バルボア)イベント等に出演。<br>
              </p>
              <p>
                2022年<br>
                増圭介氏率いるジャズ吹奏楽団 Wind Rootsに参加、クラリネットを担当。
              </p>
            </div>
          </div>
          <div class="description -english" v-if="selectedLanguage === 'english'">
            <p>
              Clarinet and saxophone player
            </p>
            <p>
              In addition to the three-piece swing jazz band "Ryu Muraki Three" (clarinet, piano, and drums), which follows
              the style of the Benny Goodman Trio, he also leads his own band and performs as a supporting member around the
              Tokyo metropolitan area.
            </p>
            <div class="history">
              <p class="history-title">
                <font-awesome-icon :icon="['fas', 'landmark']" />
                Biography
              </p>
              <p>
                Started playing clarinet in junior high school brass band and discovered jazz.
              </p>
              <p>
                2018<br>
                He joined the Modern Jazz Society of Waseda University.
                While a member of the club, he held executive positions and began performing live in earnest.
              </p>
              <p>
                2020<br>
                He joined the Rikkyo University New Swingin' Herd regular band, playing tenor saxophone. Participated in the
                51st Yamano Gakki Big Band Jazz Contest held in the same year.
              </p>
              <p>
                2021<br>
                He formed Ryu Muraki Three. After a period of preparation, they performed at live houses in the Tokyo
                metropolitan area, as well as at the "Keyaki Music Festival Jazz in FUCHU 2022" and swing dance (Lindy Hop
                Balboa) events held in Tokyo, etc.
              </p>
              <p>
                2022<br>
                He joined Wind Roots, a jazz brass band led by Keisuke Masu, and played clarinet.
              </p>
              <p>
                He studied clarinet under Hideo Masuda and Tatsuzo Akasaka, tenor saxophone and composition under Akihiro
                Yoshimoto, and jazz theory under Harunobu Tsuchida.
              </p>
              <p>
                He was selected for the Age-U prize in the woodwind section of the 22nd Osaka International Music
                Competition. Participated in the Clarinet Camp in Mito organized by Yumeki Terunuma. He participated in the
                JOY Youth Orchestra based in Yokohama City as the 2nd clarinet.
              </p>
              <p>
                While focusing on jazz, he has also been focusing on classical music and improvisation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" src="./style.scss" scoped></style>
