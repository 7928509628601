<script setup>
import { ref, onMounted } from "vue";
import { RouterLink } from "vue-router";
import { db } from "@/firebase";
import { collection, where, orderBy, query, getDocs } from "firebase/firestore";
import { getYear, getMonth, getDay } from "@/assets/js/common";
import dayjs from "dayjs";

const shrinkTitle = ref(null);
const shrinkElement = ref(null);
let lives = ref([]);

const fetchLiveInfo = async () => {
  try {
    const today = dayjs().format('YYYY-MM-DD');
    const q = query(
      collection(db, "lives"),
      where("status", "==", 0),
      where("event_date", ">=", today),
      orderBy("event_date", "asc")
    );
    const querySnapshot = await getDocs(q);

    const liveList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    lives.value = liveList.filter((live) => {
      return (live.deleted_at === undefined || live.deleted_at === "")
    })
    lives.value.map((live) => {
      live.weekday = dayjs(live.event_date).format('ddd')
    })
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  fetchLiveInfo();
  window.addEventListener("scroll", shrinkTitleOnScroll);
  window.addEventListener("scroll", shrinkElementOnScroll);
});

const shrinkTitleOnScroll = () => {
  const element = shrinkTitle.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add("-show");
      window.removeEventListener("scroll", shrinkTitleOnScroll);
    }
  }
};
const shrinkElementOnScroll = () => {
  const element = shrinkElement.value;
  if (element) {
    const elementPosition = element.getBoundingClientRect().top;
    if (elementPosition - window.innerHeight <= 0) {
      element.classList.add("-show");
      window.removeEventListener("scroll", shrinkElementOnScroll);
    }
  }
};
</script>

<template>
  <div class="schedule" id="schedule">
    <div class="background-content">
      <p class="background-right shrink-title -right" ref="shrinkTitle">LIVE</p>
    </div>
    <div class="contents shrink-element" ref="shrinkElement">
      <div class="live-schedule">
        <p class="heading">SCHEDULE</p>
        <transition-group name="slide" tag="div" class="live-list" :css="false">
          <RouterLink
            v-for="live in lives"
            :key="live.id"
            :to="{ name: 'post', params: { id: live.id } }"
          >
            <div class="live-link">
              <div class="date" v-if="live.event_date">
                <p class="year">{{ getYear(live.event_date) }}</p>
                <p class="day">
                  {{ getMonth(live.event_date) }}/{{ getDay(live.event_date) }}
                </p>
                <p class="weekday">
                  {{ live.weekday }}
                </p>
              </div>
              <div class="live-name">
                <div class="name">
                  <p>{{ live.title }}</p>
                </div>
                <div class="place">
                  <p>{{ live.venue_name }}</p>
                </div>
              </div>
              <font-awesome-icon icon="angle-right" class="icon" />
            </div>
          </RouterLink>
        </transition-group>
      </div>
    </div>
  </div>
</template>

<style lang="scss" src="./style.scss" scoped></style>
